import { graphql, Link } from "gatsby";
import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

type IndexProps = {
  data: {
    allMarkdownRemark: {
      nodes: Page[];
    };
  };
};

type Page = {
  frontmatter: {
    title: string;
    slug: string;
    date: Date;
    day: string;
    month: string;
    year: string;
    formattedDate: string;
  };
};

const IndexPage: React.FC<IndexProps> = props => (
  <Layout>
    <Seo title="Home" />
    <h1>Kris Jenkins' Blog</h1>

    <hr />

    <h3 className="my-3">Latest</h3>
    {props.data.allMarkdownRemark.nodes
      .slice(0, 3)
      .map(({ frontmatter }: Page, index: number) => {
        const path = `/${frontmatter.year}/${frontmatter.month}/${frontmatter.slug}.html`;
        return (
          <div key={index} className="row">
            <small className="col-md-3 col-12 d-none d-md-block text-end">
              {frontmatter.formattedDate}
            </small>
            <h4 className="col-md-9 col-12">
              <Link to={path}>{frontmatter.title}</Link>
            </h4>
          </div>
        );
      })}

    <h3 className="my-3">Older</h3>
    {props.data.allMarkdownRemark.nodes
      .slice(3, -1)
      .map(({ frontmatter }: Page, index: number) => {
        const path = `/${frontmatter.year}/${frontmatter.month}/${frontmatter.slug}.html`;
        return (
          <div key={index} className="row">
            <small className="col-md-3 col-12 d-none d-md-block text-end">
              {frontmatter.formattedDate}
            </small>
            <div className="col-md-9 col-12">
              <Link to={path}>{frontmatter.title}</Link>
            </div>
          </div>
        );
      })}
  </Layout>
);

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { published: { ne: false } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        fileAbsolutePath
        frontmatter {
          title
          slug
          tags
          date
          formattedDate: date(formatString: "MMM DD, yyyy")
          year: date(formatString: "yyyy")
          month: date(formatString: "MM")
          day: date(formatString: "DD")
        }
      }
    }
  }
`;

export default IndexPage;
